import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import ArrowButton from 'components/Button/ArrowButton';
import Layout from 'components/Layout';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium, BaseP } from 'components/typography';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { HeroSection } from 'components/wrappers';
import { BasicDiv } from 'components/wrappers';
import { TextDesc } from 'components/wrappers';
import HeroBg from 'images/all-in-one/all-in-one-bg.png';
import ShipkooAllInOne from 'images/all-in-one/shipkoo-all-in-one.png';
import ContactImage from 'images/contact-us-shipkoo.png';
import { Phone, TextSection, PhoneNumbers } from 'components/pages/Contact/styled';
import {UnorderedList} from 'components/blog/SingleBlogStyle';
import ContactForm from 'components/pages/Contact/ContactForm/ContactFormV2';
import ScheduleConsultation from 'images/schedule-consultation.png'

import { DetailContent, PageContainer, MainSection, ShippingSectionURL, AllInOneSection }  from 'components/pages/Contact/styledElements';

const ContentSmall = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:25px;
`;

const ContentSmallTitle = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:0px;
`;

const ContentSmallBg = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:25px;
  background: #FCF1DE;
  text-align: center;
  border-bottom:2px solid #000;
`;

const ContentSmallCenter = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:10px;
  text-align: center;
`;

const ContentSmallSpan = styled.span`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
`;

const Clear  = styled.div`
  clear:both;
  padding:0 !important;
`;

const Row  = styled.div`
  max-width:1200px;
  margin-right:auto;
  margin-left:auto;
  padding-top: 20px;
  padding-bottom: 20px;

  .forms-container {
    width: 100%;
  }

  .contact-column {
    padding: 20px;
  }

  #contact-form-container form input, #contact-form-container form textarea {
    border: 2px solid rgb(0,175,133);
    text-transform: capitalize;
    padding: 6px 15px;
    border-radius: 10px;
  }

  #contact-form-container form input[type=email], #contact-form-container form textarea {
    text-transform: none;
  }

  #contact-form-container form {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .box-shadow {
    -webkit-box-shadow: 0px 0px 8px 5px rgba(181,181,181,0.3);
    box-shadow: 0px 0px 8px 5px rgba(181,181,181,0.3);
    padding: 20px 20px;
  }

  #calendly-form-container img {
    margin-left: auto;
    margin-right: auto;
  }

  #calendly-form-container h2 {
    font-weight: 500;
    text-align: center;
    font-size: 25px;
    margin-top: 30px;
    line-height: 1.5;
    min-height: 105px;
  }

  #calendly-form-container a {
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    margin-top: 25px;
  }

  #calendly-form-container .btn-outlined {
    width: 100%;
    height: 55px;
    border-radius: 10px;
    background: transparent;
    color: #00af85;
    font-size: 18px;
    border: 2px solid #00af85;
    transition: 0.5s;
    margin-bottom: 19px;
  }

  #calendly-form-container .btn-outlined:hover {
    cursor: pointer;
    background: #00af85;
    color: #ffffff;
  }

  @media only screen and (max-width: 981px) {
    #contact-form-container form > div {
      margin-left: 0;
      margin-right: 0;
    }
    #calendly-form-container .box-shadow {
      padding: 20px 30px;
    }
  }

  @media only screen and (min-width: 982px) {
    .contact-column {
      display: inline-block;
      width: 50%;
      vertical-align: top;
    }

    #calendly-form-container h2 {
      font-size: 35px;
    }

    #calendly-form-container a {
      margin-top: 55px;
    }

    #calendly-form-container .box-shadow {
      padding-top: 80px;
    }

    .box-shadow {
      padding: 30px 20px;
      min-height: 479px;
    }

    .forms-container {
      margin-left: -10px;
      margin-right: -10px;
    }

    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const TabNavigation = styled.div`
  width:25%;
  display:inline-block;

`;

const HeroHeader = styled.h1`
  font-size: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 500;
`;

const HeroText = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #7f7f7f;
  line-height:1.8;
`;
const Breadcrumbs = styled.p`
    padding: 25px 0px 25px 50px;
    width:100%
`;

const Contact2 = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        <title>{intl.formatMessage({ id: "contact-meta-title" })}</title>
        
        <link rel="alternate" href={intl.formatMessage({ id: "contact-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "contact-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "contact-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "contact-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content={intl.formatMessage({ id: "contact-meta-description" })} />
        <meta property="og:title" content={intl.formatMessage({ id: "contact-meta-title" })} />
        <meta property="og:description" content={intl.formatMessage({ id: "contact-meta-description" })} />
        <meta name="twitter:title" content={intl.formatMessage({ id: "contact-meta-title" })} />
        <meta name="twitter:description" content={intl.formatMessage({ id: "contact-meta-description" })} />
      </Helmet>
      <PageContainer>
        <Breadcrumbs><Link to="/">Home</Link> / {intl.formatMessage({ id: "menu-contact" })}</Breadcrumbs>
        <MainSection>
          <div id="hero-section" className={intl.formatMessage({ id: "language" })}>
            <img className="hero-bg" src={HeroBg} alt="all in one digital platform"/>
            <Row>
                <div className="column-half">
                  <div className="content-container">
                    <BaseP>{intl.formatMessage({ id: "c-contact-header" })}</BaseP>
                    <HeroHeader>{intl.formatMessage({ id: "c-contact-subheader" })}</HeroHeader>
                    <HeroText>{intl.formatMessage({ id: "c-contact-description" })}</HeroText>
                    <div className="contact-info">
                      <PhoneNumbers>
                        <BaseP>{intl.formatMessage({ id: "c-contact-email-title" })}</BaseP>
                        <Phone href="mailto:info@shipkoo.com">{intl.formatMessage({ id: "c-contact-email" })}</Phone>
                      </PhoneNumbers>
                      <a href="#contact-info" id="contact-info">
                        <ArrowButton >
                          {intl.formatMessage({ id: "c-contact-button" })}
                        </ArrowButton>
                      </a>
                      <a style={{marginTop: 20, display: 'block'}} href="https://api.whatsapp.com/send/?phone=85246223855&text&type=phone_number&app_absent=0" id="contact-info" target="_blank" rel="noreferrer">
                        <ArrowButton >
                          Whatsapp
                        </ArrowButton>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="column-half last">
                  <div className="content-container">
                    <img src={ContactImage} alt="shipkoo contact us" />
                  </div>
                </div>
                <Clear></Clear>
            </Row>
          </div>
        </MainSection>
        <MainSection>
          <Row>
            <div className="forms-container">
              <div id="contact-form-container" className="contact-column">
                <div className="box-shadow">
                  <ContactForm />
                </div>
              </div>
              <div id="calendly-form-container" className="contact-column">
                <div className="box-shadow">
                  <img src={ScheduleConsultation} alt="Schedule a Constultation" />
                  <h2>
                  {intl.formatMessage({ id: "home-contact-title" })}
                  </h2>
                  <a href="https://calendly.com/yannickdevries/15-minute-consultation" target="_blank" rel="noreferrer">
                    <button className="btn-outlined">
                      {intl.formatMessage({ id: "c-contact-form-button" })}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </Row>
        </MainSection>
      </PageContainer>
    </Layout>
  );
};

export default Contact2;
