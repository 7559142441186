import styled from 'styled-components';

import { mediaMin, mediaMax } from 'common/styledMixins';
import { breakpoint, color } from 'common/styledVars';
import { HeaderMedium, BaseP, BaseTransparentP } from 'components/typography';

export const Phone = styled.a`
  font-size: 21px;
  font-weight: 300;

  @media ${mediaMax(breakpoint.desktop - 1)} {
    color: ${color.white};
  }

  @media ${mediaMin(breakpoint.desktop)} {
    font-size: 36px;
  }
`;

export const TextSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 32px 0;

  ${BaseTransparentP} {
    opacity: 1;
    line-height: 35px;
    color: #a4b0ad;
    margin-bottom: 46px;
    max-width: 375px;
  }

  ${HeaderMedium} {
    margin: 42px 0 auto;
    max-width: 325px;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    padding: 72px 100px 91px 0;
    width: calc(50% - 48px);

    ${HeaderMedium} {
      margin: 45px 0;
    }
  }
`;

export const PhoneNumbers = styled.div`
  margin-bottom: 32px;

  ${BaseP} {
    margin-bottom: 5px;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    margin-bottom: 76px;
  }
`;
